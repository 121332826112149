@import "./mixins";

@import "./variables";


$my-app-theme: mat-dark-theme($my-app-primary, $my-app-accent, $my-app-warn);

.mat-select-value {
  color: white !important;

  span {
    display: inline-block;
    margin-top: -10px;
  }
}

.mat-progress-spinner {
  circle {
    stroke: mat-color($my-app-accent) !important;
  }

}

.mat-progress-bar {
  .mat-progress-bar-fill::after {
    background-color: mat-color($my-app-accent-lighter);
  }

  .mat-progress-bar-buffer {
    background-color: mat-color($my-app-primary);
  }

}
