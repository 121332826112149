//@import "~@angular/material/theming";
$my-app-primary: red;
$my-app-primary-text:black;
$my-app-primary-lighter: gray;
$my-app-accent: yellow;
$my-app-accent-dark: orange;
$my-app-accent-lighter: lime;
$my-app-accent-text: green;
$my-app-warn: gold;
$my-app-background:#e1e1e1;
