@import "src/sass/_variables.scss";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/angular-notifier/styles.css";


html {
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;
  overflow-x: auto !important;
  /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
  margin: 0px;
  /*removes default style*/
  display: flex;
  /*enables flex content for its children*/
  box-sizing: border-box;
  background: #ffffff;
    font-weight: 200;

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 2em;
  }

  h1,h2,h3,h4,h5,h6{
    font-weight: 300
  }
}

// .content {
//   overflow: auto !important;
// }

.componentH3 {
  padding-top: 25px;
  padding-right: 25px;
      padding-bottom: 25px;
      padding-left:20px;
  margin-left: 0;
  margin-bottom: 0;
  z-index: 10;
  color: white;
  background-color:#c8c8c8;
}

.firstCharUpper {
  text-transform: capitalize;
}

.logo {
  font-family: 'Cinzel', serif;
  font-size: 2.5em;
  color: #fff019;
  margin-top: 10px;
  margin-bottom: 10px;
}

.topbar {
  position: fixed;
    left: 0;
  background-color: #007bff;
  width: 100vw;
  margin-bottom: 50px;
}

.pointer {
  cursor: pointer;
}

.main-sidebar,
.content-wrapper {
  background-color: $my-app-background;
  color: $my-app-primary-text;
}

.labwickPanel {
  position: relative;
  margin-bottom: 15px;

  h2 {
    font-size: 1em;
  }

  header {
    background-color: black;
    color: white;
    padding: 5px;
  }

  label {
    padding: 10px;
    margin-bottom: 0;
    background-color: white;
    width: 100%;
  }
}

nav{
    height:120px;
    margin-top:-25px;
}

.navbar-toggler{
  margin-right:20px;
}

.labwickHeader {
  padding-left: 10px;

  i {
    padding: 5px;
  }
}

#notification {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-left: -20vw;
  z-index: 10000;
  width: 40vw;
  border-radius: 3px;
  cursor: pointer;

  .container {
    padding: 40px;
  }
}

.clickToHide {
  color: gray;
  font-size: 0.8em;
  position: absolute;
  bottom: 5px;
  right: 14px;
}

.msgtype-success {
  background-color: #f8fff2;
  color: #272822;
}

.msgtype-error {
  background-color: red;
  color: white;
}

.msgtype-neutral {
  background-color: #f8fff2;
  color: #272822;
}

.msgtype-warning {
  background-color: #ff5357;
  color: white;
}




.labwickFloatClosePanel{
  position:fixed;
  width:100vw;
  height:100vh;
  top:0;
  left:0;

  background-color:rgba(0,0,0,.4);
  .container{
  position: fixed;
  width:60vw;
  height:50vh;
  left:20vw;
  top:15vh;
  padding:50px;

  .close{
    position:absolute;
    top:10px;
    color:white;
    cursor: pointer;
    right:30px;
  }
  
.card-title{
  text-decoration: none !important;
  }  

  h3{
    color:#e1e1e1;
  }
}
}



//TinyMCE menu fix
.tox .tox-collection__item-label {
  word-break: unset !important;
}
